<ion-header>
    <ion-toolbar>
        <ion-title>{{ header }}</ion-title>
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <ion-icon [icon]="backIcon" slot="icon-only" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    @if (title) {
        <h3>{{ title }}</h3>
    }
    <p>{{ message }}</p>
</ion-content>
<ion-footer>
    <ion-toolbar class="footer-buttons">
        <ion-buttons>
            <ion-button (click)="close()" color="dark" fill="solid" routerDirection="backward">{{
                backText
            }}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
